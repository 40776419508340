import { useEffect, useState } from 'react';
import './App.css';
import { BookCollection, BookDetails, LabelledBook, SubjectList } from '../../types/library/bookTypes';
import { KeysByAuthorBook, ObjectKeys } from '../../types/library/objectKeyTypes';
import { ParsedBookMetadata } from '../../types/library/bookParsingTypes';
import { SubjectIndex } from '../../types/library/subjectIndexTypes';
import { UploadFileRequest, UploadFileRequestList } from '../../types/library/dataIndexingTypes';

function App() {

  enum LoadStates {
    None = "",
    FetchingKeys = "keys",
    FetchingMetadata = "metadata",
    ParsingMetadata = "parsing"
  }

  const [bookCollection, setBookCollection] = useState<BookCollection>()
  const [metadata, setMetadata] = useState<string>()
  const [keys, setKeys] = useState<KeysByAuthorBook>()
  const [error, setError] = useState(null)
  const [readyForUpload, setReadyForUpload] = useState<boolean>(false)
  const [loadState, setLoadState] = useState<LoadStates>(LoadStates.None)
  const root = "https://admin.petergregory.dev/.netlify/functions/"
  const [clearBookCollectionFileData, setClearBookCollectionFileData] = useState()
  const [uploadFileData, setUploadFileData] = useState()
  const [subjectIndex, setSubjectIndex] = useState<SubjectIndex | undefined>()


  const upload = async () => {
    const filesCleared = await clearBookCollectionFile()
    if (filesCleared) {
      uploadIndexFiles()
    }
    // clearIndexFiles
    // remove previous BookCollection.json file
    // PutObejct new BookCollection.json file
    // PutObejct new SubjectIndex.json file
  }

  const runScript = async () => {
    setLoadState(LoadStates.FetchingKeys)
    const fetchedKeys: KeysByAuthorBook = await fetchKeys()
    // console.log("fetchedKeys", (fetchedKeys))
    if (fetchedKeys) {
      const collection = await fetchData(fetchedKeys)
      if (collection) {
        const subjectIndex = indexSubjects(collection)
        setSubjectIndex(subjectIndex)
        setReadyForUpload(true)
      }
    }
  }


  const fetchData = async (fetchedKeys: KeysByAuthorBook): Promise<BookCollection> => {
    setLoadState(LoadStates.FetchingMetadata);

    const tasks = [];
    const collection: BookCollection = {}

    for (const [authorName, bookMap] of Object.entries(fetchedKeys)) {
      for (const [bookName, bookKeys] of Object.entries(bookMap)) {
        const metadataKey = bookKeys.metadata;

        if (!metadataKey) {
          throw new Error("Missing metadata key for author-book: " + authorName + bookName);
        }

        tasks.push(
          fetchBook(metadataKey).then(parsedBookMetadata => {
            if (!parsedBookMetadata) {
              throw new Error("Missing parsedBookMetadata for author-book: " + authorName + bookName);
            }
            const idByAuthorBookName = authorName + "/" + bookName;
            return processBook(idByAuthorBookName, bookKeys, parsedBookMetadata).then(processedBook => {
              if (!processedBook) {
                throw new Error("Missing processedBook for author-book: " + authorName + bookName);
              }
              collection[processedBook.label] = processedBook.book
              addBookToCollection(processedBook);
            });
          })
        );
      }
    }

    await Promise.all(tasks);
    return collection;
  };



  // const fetchData = async (fetchedKeys: KeysByAuthorBook): Promise<boolean> => {
  //   setLoadState(LoadStates.FetchingMetadata);
  //   for (const [authorName, bookMap] of Object.entries(fetchedKeys)) {
  //     for (const [bookName, bookKeys] of Object.entries(bookMap)) {
  //       const metadataKey = bookKeys.metadata;
  //       if (metadataKey) {
  //         const parsedBookMetadata: ParsedBookMetadata = await fetchBook(metadataKey);
  //         if (parsedBookMetadata) {
  //           const idByAuthorBookName = authorName + "/" + bookName;
  //           const processedBook = await processBook(idByAuthorBookName, bookKeys, parsedBookMetadata);
  //           if (processedBook) {
  //             addBookToCollection(processedBook);
  //           } else {
  //             throw new Error("Missing processedBook for author-book: " + authorName + bookName);
  //           }
  //         } else {
  //           throw new Error("Missing parsedBookMetadata for author-book: " + authorName + bookName);
  //         }
  //       } else {
  //         throw new Error("Missing metadata key for author-book: " + authorName + bookName);
  //       }
  //     }
  //   }
  //   return true;
  // };



  // const fetchData = async (fetchedKeys: KeysByAuthorBook): Promise<boolean> => {
  //   setLoadState(LoadStates.FetchingMetadata)
  //   Object.entries(fetchedKeys).map(([authorName, bookMap]) => {
  //     Object.entries(bookMap).forEach(async ([bookName, bookKeys]) => {
  //       const metadataKey = bookKeys.metadata
  //       if (metadataKey) {
  //         const parsedBookMetadata: ParsedBookMetadata = await fetchBook(metadataKey)
  //         if (parsedBookMetadata) {
  //           const idByAuthorBookName = authorName + "/" + bookName
  //           const processedBook = await processBook(idByAuthorBookName, bookKeys, parsedBookMetadata)
  //           if (processedBook) {
  //             addBookToCollection(processedBook)
  //           } else {
  //             throw new Error("Missing processedBook for author-book: " + authorName + bookName)
  //           }
  //         } else {
  //           throw new Error("Missing parsedBookMetadata for author-book: " + authorName + bookName)

  //         }
  //       }
  //       else {
  //         throw new Error("Missing metadata key for author-book: " + authorName + bookName)
  //       }
  //     })
  //   })
  //   return true
  // }

  const fetchKeys = async () => {
    const url = root + "fetchKeys";
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // console.log("Data is:", data);
      setKeys(data);
      setLoadState(LoadStates.FetchingMetadata);
      return data
    } catch (err: any) {
      console.error("Fetch keys error: ", err.message);
      setError(err.message);
      setLoadState(LoadStates.None);
    }
  }

  const fetchBook = async (metadata: string) => {
    const url = root + "fetchBook" + "?key=" + encodeURIComponent(metadata)
    try {
      fetch(url)
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // console.log("Data is:", data);
      return data
      setMetadata(metadataState => {
        return metadataState ?
          metadataState + data :
          data
      })
    } catch (err: any) {
      console.error("Fetch metadata error: ", err.message);
      setError(err.message);
      setLoadState(LoadStates.None);

    }
  }

  const processBook = async (
    idByAuthorBookName: string,
    bookKeys: ObjectKeys,
    parsedMetadata: ParsedBookMetadata) => {
    // try {

    // console.log("idByAuthorBookName is: ", idByAuthorBookName)
    // console.log("bookKeys is: ", bookKeys)
    // console.log("parsedMetadata is: ", parsedMetadata)

    let coverImageKey
    if (bookKeys.cover) { coverImageKey = bookKeys.cover }
    else { throw new Error("Missing coverImageKey for book: " + idByAuthorBookName) }

    let pdfKey
    if (bookKeys.pdf) { pdfKey = bookKeys.pdf }
    else { throw new Error("Missing pdfKey for book: " + idByAuthorBookName) }

    let details: BookDetails = {}
    let subjects: SubjectList = []

    //         if (parsedMetadata) {

    details.title = parsedMetadata.details.title
    details.titleSort = parsedMetadata.details.titleSort
    details.author = parsedMetadata.details.author
    details.authorSort = parsedMetadata.details.authorSort
    details.description = parsedMetadata.details.description
    details.publicationDate = parsedMetadata.details.publicationDate
    details.publisher = parsedMetadata.details.publisher
    subjects = parsedMetadata.subjects

    //         } else { throw new Error("Value returned from parseBookMetadata is undefined for book: " + idByAuthorBookName) }
    //       } else { throw new Error("Key for metadata returns empty string for book: " + idByAuthorBookName) }
    //     }
    const labelledBool: LabelledBook = {
      label: idByAuthorBookName,
      book: {
        details: details,
        subjects: subjects,
        coverImageKey: coverImageKey,
        pdfKey: pdfKey
      }

    }
    //     return { [idByAuthorBookName]: book };
    //   }

    // } 
    // catch {}
    return labelledBool
  }

  const addBookToCollection = (labelledBook: LabelledBook) => {
    const label = labelledBook.label
    const book = labelledBook.book
    setBookCollection(prev => {
      if (prev) {
        return ({ ...prev, [label]: book })
      } else {
        return ({ [label]: book })
      }
    })
  }

  const indexSubjects = (bookCollection: BookCollection) => {
    let subjectIndex: SubjectIndex = {}
    // console.log("bookCollection is ", bookCollection)
    // console.log("Object.entries(bookCollection) is ", Object.entries(bookCollection))
    Object.entries(bookCollection).forEach(([bookName, book]) => {
      // console.log("bookName is: ", bookName)
      if (!book.subjects) {
        console.log("Subjects list is empty for: ", bookName)
      } else {
        book.subjects.forEach(subject => {
          // console.log("subject is: ", subject)
          if (!subjectIndex[subject]) {
            subjectIndex[subject] = []
          }
          subjectIndex[subject].push(bookName)
        })
      }
    })
    return subjectIndex
  }

  const clearBookCollectionFile = async () => {
    let url = root + "clearIndexFiles"
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const clearBookCollectionFileData = await response.json();
      setClearBookCollectionFileData(clearBookCollectionFileData)
      return true
    } catch (err: any) {
      console.error("Error clearing BookCollection file: ", err.message);
      setError(err.message);
    }
  }


  const uploadIndexFiles = async () => {

    const url = root + 'uploadIndexFiles'

    const reqData: UploadFileRequestList = {
      'BookCollection': {
        fileName: 'BookCollection.json',
        data: JSON.stringify(bookCollection)
      },
      'SubjectIndex': {
        fileName: 'SubjectIndex.json',
        data: JSON.stringify(subjectIndex)
      }
    }
    const jsonString = JSON.stringify(reqData)

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: jsonString
      })

      if (response) {
        const data = await response.json()
        setUploadFileData(data)
      }


    } catch (err: any) {
      console.log(err.message)
    }
  }

  // useEffect(() => {
  //   console.log("bookCollection is: ", bookCollection)
  // }, [bookCollection])

  return (
    <div className="App">
      <h1>ADMIN</h1>
      <button onClick={runScript}>
        Run
      </button>
      <button onClick={clearBookCollectionFile}>
        clearBookCollectionFile()
      </button>

      {readyForUpload ?

        <button onClick={upload}>
          Upload
        </button> :

        <div>
          <p>
            Not Ready for Upload.
          </p>
        </div>

      }


      <div className="ouputBoxes">
        <div>
          <div className="box keys">
            <div className="headings keys">
              <h3>Keys</h3>
            </div>

            <div className="outputContainer keys">
              <div className="output keys">
                <p>

                  {keys ?

                    JSON.stringify(keys)

                    // <div>

                    //   {Object.entries(keys).map(([id, bookMap])=> {
                    //     Object.entries(bookMap).map(([bookName, bookKeys]) => {

                    //     })
                    //   })
                    //   }

                    // </div>


                    :

                    loadState == LoadStates.FetchingKeys ?

                      "Loading: data..."

                      :

                      ""}

                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="box collection">
            <div className="headings collection">
              <h3>Book Collection</h3>
            </div>

            <div className="outputContainer collection">
              <div className="output collection">
                <p>

                  {bookCollection ?
                    <div>
                      <p>{"{"}</p>
                      {Object.entries(bookCollection).map(([id, book]) => {
                        return (

                          <p>
                            <br></br>
                            "{id}": {JSON.stringify(book)}
                            <br></br>

                          </p>
                        )
                      })}
                      <p>{"}"}</p>
                    </div>

                    :

                    loadState == LoadStates.FetchingMetadata ?

                      "Loading: data..."

                      :

                      ""}

                </p>
              </div>
            </div>




          </div>

        </div>


        <div className="box">
          <div className="headings">
            <h3>clearFileData:</h3>
          </div>
          <div className="outputContainer">
            <div className="output">

              {<p> {JSON.stringify(clearBookCollectionFileData)} </p>}

            </div>
          </div>
        </div>

        <div className="box">
          <div className="headings">
            <h3>uploadFileData:</h3>
          </div>
          <div className="outputContainer">
            <div className="output">

              {<p> {JSON.stringify(uploadFileData)} </p>}

            </div>
          </div>
        </div>

        <div className="box">
          <div className="headings">
            <h3>subjectIndex:</h3>
          </div>
          <div className="outputContainer">
            <div className="output">

              {<p> {JSON.stringify(subjectIndex)} </p>}

            </div>
          </div>
        </div>

      </div>
    </div >
  );
}

export default App;
